import React from "react";
import "./App.css";
import ContentSection from "./components/molecules/ContentSection";
import FooterSection from "./components/molecules/FooterSection";
import LandingSection from "./components/molecules/LandingSection";
import withRoot from "./withRoot";

declare global {
  interface Window {
    dataLayer: any;
  }
}

function App() {
  window.dataLayer.push({
    event: "InitialPageView",
  });
  return (
    <React.Fragment>
      <LandingSection />
      <ContentSection />
      <FooterSection />
    </React.Fragment>
  );
}

export default withRoot(App);
